.ui {
  background: rgba(255, 255, 255, 0.4);
  backdrop-filter: blur(10px);
  -webkit-backdrop-filter: blur(10px);
  box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
  border: 0px;/*1px solid rgba(255, 255, 255, 0.3);*/
  border-radius: 16px;
  font-size: 16px;
  text-align: center;
  text-decoration: none;
  padding: 5px 15px;
  margin: 4px 2px;
  display: inline-block;
  cursor: pointer;
}

.topLeft {
  position: absolute;
  top: 0px;
  left: 0px;
}

.topRight {
  position: absolute;
  top: 0px;
  right: 0px;
}

.topRight>* {
  margin-left: auto;
  margin-right: 0px;
}

.topMiddle {
  position: absolute;
  top: 0px;
  margin: 0 auto;
}

.bottomLeft {
  position: absolute;
  bottom: 0px;
  left: 0px;
}

.bottomRight {
  position: absolute;
  bottom: 0px;
  right: 0px;
}

.bottomRight>* {
  margin-left: auto;
  margin-right: 0px;
}

.bottomMiddle {
  position: absolute;
  bottom: 0px;
  margin: 0 auto;
}

.centered {
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
}

.templateRow {
  display: flex;
  flex: 25%;
  padding: 0 4px;
}

.templateColumn {
  flex-wrap: wrap;
  padding: 0 4px;
}

.block {
  display: block;
}

.unselectable {
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}